import type { FC } from 'react'

import { useGetBlockQuery } from '@redux/features/homePage/api'

import { pageBlockList } from '@constants/blockList/pageBlockList'

type Props = {
  blockId: number
}

const ContentBlock: FC<Props> = ({ blockId }) => {
  const { data } = useGetBlockQuery(blockId)
  const Element = data?.type && pageBlockList[data.type]

  return Element && <Element data={data} />
}

export default ContentBlock
