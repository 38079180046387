import type { FC } from 'react'
import { memo } from 'react'

import SearchForm from '@components/mainPage/mainBlock/searchTrains/search/Search'

import Title from './Title'
import Info from './info'
import { WrapperSearchTrains } from './styles'

type Props = {
  title?: string
}

const SearchTrains: FC<Props> = () => {
  return (
    <WrapperSearchTrains>
      <SearchForm />
      <Title />
      <Info />
    </WrapperSearchTrains>
  )
}

export default memo(SearchTrains)
