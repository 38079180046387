import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { type FC, useEffect } from 'react'

import { cleanCheckoutOnNewSearch } from '@redux/features/checkout/checkout.slice'
import type { HomePageEntityType } from '@redux/features/homePage/types/blocksHomePage'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectIsPageInteraction } from '@reduxCommonApp/commonApp.selectors'

import Header from '@components/header/Header'
import ContentBlocks from '@components/mainPage/mainBlock/contentBlocks/ContentBlocks'
import SearchTrains from '@components/mainPage/mainBlock/searchTrains'
import useIsMobile from '@hooks/mediaQueries/useIsMobile'
import MetaData from '@services/seo/components/MetaData'
import { MainPageContainer, MainPageContainerContentWrapper } from '@styles/mainPage/mainPageLayout'
import { type DefaultServerSidePropsType } from '@utils/serverSideProps/getDefaultServerSideProps'
import Footer from '@widgets/footer/Footer'

const PopupsRoot = dynamic(() => import('@components/popups/PopupsRoot'))
const MapWithSearchStations = dynamic(() => import('@components/map/MapWithSearchStations'))

export type MainPageProps = DefaultServerSidePropsType & {
  customerSupportLink: string
  metaTags: HomePageEntityType['field_metatags'] | null
  noIndex?: boolean
}

const RootPage: FC<MainPageProps> = ({ customerSupportLink, noIndex, redirectUrl, ...props }) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const isPageInteraction = useAppSelector(selectIsPageInteraction)
  const isMobile = useIsMobile()

  useEffect(() => {
    dispatch(cleanCheckoutOnNewSearch())
    redirectUrl && void router.push(redirectUrl)
  }, [])

  if (redirectUrl) return

  return (
    <>
      <MetaData {...props} noIndex={noIndex} />
      <MainPageContainer>
        {isPageInteraction && <PopupsRoot />}
        {!isMobile && <MapWithSearchStations />}
        <Header />
        <MainPageContainerContentWrapper>
          <SearchTrains />
          <ContentBlocks />
        </MainPageContainerContentWrapper>
        <Footer customerSupportLink={customerSupportLink} />
      </MainPageContainer>
    </>
  )
}

export default RootPage
